/*
 * @Author: your name
 * @Date: 2021-11-08 10:17:17
 * @LastEditTime: 2022-03-21 10:11:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/main.js
 */
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router/index.js'
import storeLocal from 'store';
import Cookie from 'js-cookie'
import moment from "moment";
//引入
import Vant from 'vant'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import _ from 'lodash'
import '../node_modules/vant/lib/index.css'
import 'amfe-flexible/index'
import gallery from 'img-vuer'
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
Vue.use(gallery, {
  swipeThreshold: 150, // default 100
  isIndexShow: false, // show image index, default true
  useCloseButton: false, // trigger gallery close with close button, default true
  preload: false, // preload images in the same group, default true
  sliderBackgroundColor: 'rgba(0,0,0)',
  sliderZIndex: 1001,
})
Vue.use(Vant)
Vue.use(VueQuillEditor)
Vue.use(VueEasytable);
Vue.prototype.$Cookie = Cookie;

Vue.config.productionTip = false
Vue.prototype.$storeLocal = storeLocal;
Vue.prototype.$moment = moment;
Vue.prototype._ = _;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
