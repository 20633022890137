/*
 * @Author: your name
 * @Date: 2021-11-08 10:33:58
 * @LastEditTime: 2022-07-22 10:44:09
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/router/index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
// 解决重复点击导航报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);

export const routes = [{
    path: '/',
    redirect: '/login',

}, {
    path: '/login',
    component: () => import('../views/login/index.vue')
},
{
    path: "",
    component: () => import('../layout/index.vue'),
    children:[
        {
            path: '/lesson_plan',
            name: '教案',
            component: () => import('@/views/lesson_plan/index.vue'),
            meta: {
                role: ['teacher']
            },
            redirect: "/lesson_plan/list/:id/:schoolYearId",
            children:[
                {
                    path: 'list/:id/:schoolYearId',
                    name: 'list',
                    component: () => import('@/views/lesson_plan/list/index.vue'),
                    meta: {
                        role: ['teacher'],
                        keepAlive: true
                    },
                },
                {
                    path: 'details/:classesId/:schoolYearId/:id',
                    name: 'details',
                    father: '/lesson_plan',
                    component: () => import('@/views/lesson_plan/details/index.vue'),
                    meta: {
                        role: ['teacher']
                    },
                }
            ]
        },
        {
            path: '/pd', // 
            name: '探究资讯',
            component: resolve => require(['@/views/pd/index.vue'], resolve),
            meta: {
                role: ['teacher']
            },
            redirect: "/pd/home",
            children:[
                {
                    path: 'home',
                    name: 'PD',
                    component: resolve => require(['@/views/pd/list/index.vue'], resolve),
                    meta: {
                        role: ["teacher"],
                        keepAlive: true
                    },
                },
                {
                    path: 'pdDetails/:id/:typeId',
                    name: 'pdDetails',
                    component: resolve => require(['@/views/pd/details/index.vue'], resolve),
                    meta: {
                        role: ["teacher"],
                    },
                },
                {
                    path: 'pdf/:id',
                    name: 'pdfList',
                    component: resolve => require(['@/views/pd/details/pdfList.vue'], resolve),
                    meta: {
                        role: ["teacher"],
                    },
                },

            ]
        }
    ]
}

];

const router = new VueRouter({
    routes,
});
router.beforeEach((to,from,next) => {
    // console.log(to,from,next)
    // console.log(localStorage.getItem('courseToken'))
    if(!sessionStorage.getItem('courseToken')){
        if (to.path != '/login') {
            next('/login');
        }else{
            next()
        }
    }else{
        next()
    }
})
export default router;