/*
 * @Author: your name
 * @Date: 2021-11-08 13:45:57
 * @LastEditTime: 2022-03-09 16:29:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
let state = {
    show:false,
    weekVal:"2",
    showTemplatePop:false,// 展示反思模版弹框
    IsDetails:false,//教案是否为编辑状态
};
let getters = {};
let mutations = {
    setIsShowLogo:(state,show) =>{
        state.show = show;
    },
    setWeekVal:(state,weekVal) => {
        state.weekVal = weekVal;
        console.log(state.weekVal)
    },
    setShowTemplatePop:(state,showTemplatePop) => {
        state.showTemplatePop = showTemplatePop;
    },
    setIsDetails:(state,IsDetails) => {
        state.IsDetails = IsDetails
    }
};
let actions = {}
let modules = {}
export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules
})