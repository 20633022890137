<!--
 * @Author: your name
 * @Date: 2021-11-08 10:17:17
 * @LastEditTime: 2021-12-23 17:17:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import "@/utils/rem.js";
export default {
  name: "App",
};
</script>

<style lang="scss">
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 !important;
  padding: 0 !important;
  font-family:arial;
  }
</style>
